
export const AppMenus = {
    
	navbarTopRightItems: [],
	navbarTopLeftItems: [],
	navbarSideLeftItems: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-th-large text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/counter",
    "label": "Counter",
    "icon": "pi pi-th-large text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/lu_codigodepais",
    "label": "Lu Codigodepais",
    "icon": "pi pi-th-large text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/lu_codigodeunidades",
    "label": "Lu Codigodeunidades",
    "icon": "pi pi-th-large text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/podata",
    "label": "Podata",
    "icon": "pi pi-th-large text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/prevalida",
    "label": "Prevalida",
    "icon": "pi pi-th-large text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/table7",
    "label": "Table7",
    "icon": "pi pi-th-large text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbl_places",
    "label": "Tbl Places",
    "icon": "pi pi-th-large text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tickets",
    "label": "Tickets",
    "icon": "pi pi-th-large text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/users",
    "label": "Users",
    "icon": "pi pi-th-large text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/waybills",
    "label": "Waybills",
    "icon": "pi pi-th-large text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/roles",
    "label": "Roles",
    "icon": "pi pi-th-large text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/permissions",
    "label": "Permissions",
    "icon": "pi pi-th-large text-primary",
    "iconcolor": "",
    "target": "",
    
  }
],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: '',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}