export default {
	startsWith: 'Commence par',
	contains: 'Contient',
	notContains: 'Non contient',
	endsWith: 'Termine par',
	equals: 'Équivaut à',
	notEquals: 'Non équivaut à',
	noFilter: 'Pas de filtre',
	lt: 'Moins que',
	lte: 'Inférieur ou égal à',
	gt: 'Plus grand que',
	gte: 'Plus grand que ou égal à',
	dateIs: 'La date est',
	dateIsNot: "La date n'est pas",
	dateBefore: 'La date est avant',
	dateAfter: 'La date est après',
	clear: 'Dégager',
	apply: 'Appliquer',
	matchAll: 'Correspondre à tous',
	matchAny: "Correspondre à n'importe quel",
	addRule: 'Ajouter une règle',
	removeRule: 'Supprimer la règle',
	accept: 'Oui',
	reject: 'Non',
	choose: 'Choisir',
	upload: 'télécharger',
	cancel: 'Annuler',
	dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
	dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
	dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
	monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
	monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
	today: "Aujourd'hui",
	weekHeader: 'Semaine',
	firstDayOfWeek: 1,
	dateFormat: 'dd/mm/yy',
	weak: 'Faible',
	medium: 'Moyen',
	strong: 'Forte',
	passwordPrompt: 'Entrez un mot de passe',
	emptyFilterMessage: 'Aucun résultat trouvé',
	emptyMessage: 'Aucune option disponible',
	validations: {
		required: "Une valeur est requise.",
		email: "La valeur n'est pas une adresse e-mail valide",
		minLength: "Ce champ doit contenir au moins ${min} caractères",
		maxLength: "La longueur maximale autorisée est de ${max}",
		minValue: "La valeur minimale autorisée est ${min}",
		maxValue: "La valeur maximale autorisée est ${max}",
		numeric: "La valeur doit être numérique",
		ipAddress: "La valeur n'est pas une adresse IP valide",
		sameAs: "La valeur doit être égale à la valeur ${otherName}",
	}
}