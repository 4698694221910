
import { ApiService } from '@/services/api'
import { StorageService } from '../services/storage'
export const auth = {
	namespaced: true,
	state: {
		user: null,
		userPages: [],
		userRoles: [],
	},
	getters: {
		getLoginToken(state) {
			let token = StorageService.getToken() || null;
			return token;
		},
		getUserRole(state) { // return default role
			if(state.userRoles.length) {
				return state.userRoles[0];
			}
			return "";
		}
	},
	mutations: {
		setUserData(state, user) {
			state.user = user
		},
	},
	actions: {
		getUserData: async ({ commit }, payload) => {
			return new Promise((resolve, reject) => {
				ApiService.get("account/currentuserdata")
					.then(resp => {
						let data = resp.data;
						commit("setUserData", data);
						resolve(data);
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		login: ({ commit, dispatch }, payload) => {
			return new Promise((resolve, reject) => {
				ApiService.post(payload.url, payload.formData).then(resp => {
					const loginData = resp.data;
					const rememberUser = payload.rememberUser;
					dispatch('saveLoginData', { loginData, rememberUser });
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				})
			})
		},
		saveLoginData: ({ commit }, payload) => {
			const { loginData, rememberUser } = payload;
			if(loginData.user && loginData.token){
				StorageService.saveLoginData(loginData, rememberUser);
			}
		},
		logout: ({ commit }) => {
			// Remove the token and remove Authorization header from Api Service as well 
			StorageService.removeLoginData();
			ApiService.removeHeader();
		},
	}
}
