import { createStore } from "vuex"
import { app } from "./app.js"
//import appStore from "./app_store.js";
import { auth } from "./auth.js"
import { counter } from "./counter.js"
import { lu_codigodepais } from "./lu_codigodepais.js"
import { lu_codigodeunidades } from "./lu_codigodeunidades.js"
import { permissions } from "./permissions.js"
import { podata } from "./podata.js"
import { prevalida } from "./prevalida.js"
import { roles } from "./roles.js"
import { table7 } from "./table7.js"
import { tbl_places } from "./tbl_places.js"
import { tickets } from "./tickets.js"
import { users } from "./users.js"
import { waybills } from "./waybills.js"

//const { state, getters, mutations, actions } = appStore;

const store = createStore({
	modules: {
		app,
		auth,
		counter,
		lu_codigodepais,
		lu_codigodeunidades,
		permissions,
		podata,
		prevalida,
		roles,
		table7,
		tbl_places,
		tickets,
		users,
		waybills
	},
	// enable strict mode (adds overhead!)
	// for dev mode only
	//strict: process.env.DEV
});
export default store;