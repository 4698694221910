
import { createRouter, createWebHashHistory } from 'vue-router';
export default ({ store, auth }) => {
	let routes = [
		//Dashboard routes


//counter routes
			{
				path: '/counter/:index?/:fieldName?/:fieldValue?',
				name: 'counterlist',
				component: () => import('./pages/counter/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/counter/view/:id', 
				name: 'counterview', 
				component: () => import('./pages/counter/view.vue'), 
				props: true
			},
	
			{ 
				path: '/counter/add', 
				name: 'counteradd', 
				component: () => import('./pages/counter/add.vue'), 
				props: true
			},
	
			{ 
				path: '/counter/edit/:id', 
				name: 'counteredit', 
				component: () => import('./pages/counter/edit.vue'), 
				props: true
			},
		

//lu_codigodepais routes
			{
				path: '/lu_codigodepais/:index?/:fieldName?/:fieldValue?',
				name: 'lu_codigodepaislist',
				component: () => import('./pages/lu_codigodepais/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/lu_codigodepais/view/:id', 
				name: 'lu_codigodepaisview', 
				component: () => import('./pages/lu_codigodepais/view.vue'), 
				props: true
			},
	
			{ 
				path: '/lu_codigodepais/add', 
				name: 'lu_codigodepaisadd', 
				component: () => import('./pages/lu_codigodepais/add.vue'), 
				props: true
			},
	
			{ 
				path: '/lu_codigodepais/edit/:id', 
				name: 'lu_codigodepaisedit', 
				component: () => import('./pages/lu_codigodepais/edit.vue'), 
				props: true
			},
		

//lu_codigodeunidades routes
			{
				path: '/lu_codigodeunidades/:index?/:fieldName?/:fieldValue?',
				name: 'lu_codigodeunidadeslist',
				component: () => import('./pages/lu_codigodeunidades/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/lu_codigodeunidades/view/:id', 
				name: 'lu_codigodeunidadesview', 
				component: () => import('./pages/lu_codigodeunidades/view.vue'), 
				props: true
			},
	
			{ 
				path: '/lu_codigodeunidades/add', 
				name: 'lu_codigodeunidadesadd', 
				component: () => import('./pages/lu_codigodeunidades/add.vue'), 
				props: true
			},
	
			{ 
				path: '/lu_codigodeunidades/edit/:id', 
				name: 'lu_codigodeunidadesedit', 
				component: () => import('./pages/lu_codigodeunidades/edit.vue'), 
				props: true
			},
		

//permissions routes
			{
				path: '/permissions/:index?/:fieldName?/:fieldValue?',
				name: 'permissionslist',
				component: () => import('./pages/permissions/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/permissions/view/:id', 
				name: 'permissionsview', 
				component: () => import('./pages/permissions/view.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/add', 
				name: 'permissionsadd', 
				component: () => import('./pages/permissions/add.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/edit/:id', 
				name: 'permissionsedit', 
				component: () => import('./pages/permissions/edit.vue'), 
				props: true
			},
		

//podata routes
			{
				path: '/podata/:index?/:fieldName?/:fieldValue?',
				name: 'podatalist',
				component: () => import('./pages/podata/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/podata/view/:id', 
				name: 'podataview', 
				component: () => import('./pages/podata/view.vue'), 
				props: true
			},
	
			{ 
				path: '/podata/add', 
				name: 'podataadd', 
				component: () => import('./pages/podata/add.vue'), 
				props: true
			},
	
			{ 
				path: '/podata/edit/:id', 
				name: 'podataedit', 
				component: () => import('./pages/podata/edit.vue'), 
				props: true
			},
		

//prevalida routes
			{
				path: '/prevalida/:index?/:fieldName?/:fieldValue?',
				name: 'prevalidalist',
				component: () => import('./pages/prevalida/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/prevalida/view/:id', 
				name: 'prevalidaview', 
				component: () => import('./pages/prevalida/view.vue'), 
				props: true
			},
	
			{ 
				path: '/prevalida/add', 
				name: 'prevalidaadd', 
				component: () => import('./pages/prevalida/add.vue'), 
				props: true
			},
	
			{ 
				path: '/prevalida/edit/:id', 
				name: 'prevalidaedit', 
				component: () => import('./pages/prevalida/edit.vue'), 
				props: true
			},
		

//roles routes
			{
				path: '/roles/:index?/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
		

//table7 routes
			{
				path: '/table7/:index?/:fieldName?/:fieldValue?',
				name: 'table7list',
				component: () => import('./pages/table7/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/table7/view/:id', 
				name: 'table7view', 
				component: () => import('./pages/table7/view.vue'), 
				props: true
			},
	
			{ 
				path: '/table7/add', 
				name: 'table7add', 
				component: () => import('./pages/table7/add.vue'), 
				props: true
			},
	
			{ 
				path: '/table7/edit/:id', 
				name: 'table7edit', 
				component: () => import('./pages/table7/edit.vue'), 
				props: true
			},
		

//tbl_places routes
			{
				path: '/tbl_places/:index?/:fieldName?/:fieldValue?',
				name: 'tbl_placeslist',
				component: () => import('./pages/tbl_places/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/tbl_places/view/:id', 
				name: 'tbl_placesview', 
				component: () => import('./pages/tbl_places/view.vue'), 
				props: true
			},
	
			{ 
				path: '/tbl_places/add', 
				name: 'tbl_placesadd', 
				component: () => import('./pages/tbl_places/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tbl_places/edit/:id', 
				name: 'tbl_placesedit', 
				component: () => import('./pages/tbl_places/edit.vue'), 
				props: true
			},
		

//tickets routes
			{
				path: '/tickets/:index?/:fieldName?/:fieldValue?',
				name: 'ticketslist',
				component: () => import('./pages/tickets/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/tickets/view/:id', 
				name: 'ticketsview', 
				component: () => import('./pages/tickets/view.vue'), 
				props: true
			},
	
			{ 
				path: '/tickets/add', 
				name: 'ticketsadd', 
				component: () => import('./pages/tickets/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tickets/edit/:id', 
				name: 'ticketsedit', 
				component: () => import('./pages/tickets/edit.vue'), 
				props: true
			},
		

//users routes
			{
				path: '/users/:index?/:fieldName?/:fieldValue?',
				name: 'userslist',
				component: () => import('./pages/users/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/users/view/:id', 
				name: 'usersview', 
				component: () => import('./pages/users/view.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'usersaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usersaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/users/add', 
				name: 'usersadd', 
				component: () => import('./pages/users/add.vue'), 
				props: true
			},
	
			{ 
				path: '/users/edit/:id', 
				name: 'usersedit', 
				component: () => import('./pages/users/edit.vue'), 
				props: true
			},
		

//waybills routes
			{
				path: '/waybills/:index?/:fieldName?/:fieldValue?',
				name: 'waybillslist',
				component: () => import('./pages/waybills/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/waybills/view/:id', 
				name: 'waybillsview', 
				component: () => import('./pages/waybills/view.vue'), 
				props: true
			},
	
			{ 
				path: '/waybills/add', 
				name: 'waybillsadd', 
				component: () => import('./pages/waybills/add.vue'), 
				props: true
			},
	
			{ 
				path: '/waybills/edit/:id', 
				name: 'waybillsedit', 
				component: () => import('./pages/waybills/edit.vue'), 
				props: true
			},
		

		
		
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
		
		
		{ 
			path:  '/error/forbidden', 
			name: 'forbidden', 
			component: () => import('./pages/errors/forbidden.vue'),
			props: true
		},
		{ 
			path: '/error/notfound', 
			name: 'notfound',
			component: () => import('./pages/errors/pagenotfound.vue'),
			props: true
		},
		{
			path: '/:catchAll(.*)', 
			component: () => import('./pages/errors/pagenotfound.vue')
		}
	];
	
	const user = store.state.auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}


	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			//if(savedPostion) return savedPostion;
			return { x:0, y:0 }
		}
	});

	
	router.beforeEach((to, from, next) => {
		const user = store.state.auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		//authenticate user
		if (authRequired && !user) {
			//user is not logged. redirect to login
			return next({ path: '/',  query: { nexturl: to.fullPath } });
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

		//close dialog from previous page
		store.dispatch('app/closeDialogs');

		//continue to specified route
		next();
	});

	return router;
}
